import React from "react";
// import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

// var choice = Math.floor(Math.random() * 2);
// var choice = 0;

const Benji = () => {
  return (
    <Layout>
      <Head title="Benji Friedman" />
      <h3>Benji Friedman</h3>
      <h4>
        <FaGlobe />
        Berkeley, CA
      </h4>
      {/* <p>
        <OutboundLink href="https://benjifriedman.com">Website</OutboundLink>
      </p> */}
      <p>
        <OutboundLink href="https://instagram.com/benjifriedman">
          Instagram
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/benjifriedman">Ello</OutboundLink>
      </p> */}
      {/* <p>
        <OutboundLink href="https://benjifriedman.xyz">
          Framed Prints and T-Shirts
        </OutboundLink>
      </p> */}
      <p>I am an artist and web developer living in Berkeley, CA.</p>
      <p>
        Currently / primarily making websites, sculptures, oil pastels, AI art,
        and doing photography around the Bay Area.
      </p>
      <p>
        I'm interested / open to new / multimedia collaborations if you have any
        ideas; just send me a message!
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/1-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/2-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/3-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/4-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/5-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/6-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/7-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/8-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/9-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/10-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/11-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/12-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/13-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/14-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1670878138/benjifriedman.com%202023/albany%20hill/15-benji-friedman-december-2022.jpg"
        alt="benji friedman photography 2023 bay area"
      />
      {/* <p>
        I also made <Link to="/">artnow.world</Link>. If you are interested in
        having a page on this website / project, contact me!
      </p> */}
    </Layout>
  );
};

export default Benji;
